const Sdata=[
    {
        id:1,
        title:"40% off For Your First Shopping",
        desc:"Package Contains: 1 device, 1 charging cable, 1 user manual, warranty card.",
        cover:"./images/SlideCard/slide-1.png",
    },
    {
        id:2,
        title:"20% off For Your First Shopping",
        desc:"adidas Men Adiscend M Running Shoes At NykaaMan.",
        cover:"./images/SlideCard/slide-2.png",
    },
    {
        id:3,
        title:"10% off For Your First Shopping",
        desc:"Lymio Casual Shirt for Men|| Shirt for Men|| Men Stylish Shirt || Men Printed Shirt.",
        cover:"./images/SlideCard/slide-3.png",
    },
    {
        id:4,
        title:"30% off For Your First Shopping",
        desc:"Shop charms, earrings, rings, necklaces and bracelets for the pieces that match your look, today.",
        cover:"./images/SlideCard/slide-4.png",
    },

]
export default Sdata